<!-- 支付方式设置 -->
<template>
  <section class="cont collectionSetting">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <el-table :data="tableData" border fit stripe>
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="name" label="支付方式"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ scope.row.type === 'DEFAULT' ? '默认' : '新增' }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <div :class="scope.row.status === 'ENABLED' ? '' : 'c-red'">
                {{ scope.row.status === 'ENABLED' ? '开启' : '隐藏' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <div slot="header">
              <span>操作</span>
              <el-tooltip class="item" effect="dark" content="新增" placement="top">
                <el-button type="primary" icon="el-icon-plus" class="m-left-25" size="mini" circle @click="add"></el-button>
              </el-tooltip>
            </div>
            <template slot-scope="scope">
              <el-button type="text" @click="hide(scope.row)" v-if="scope.row.status === 'ENABLED'">
                <el-link type="primary">隐藏</el-link>
              </el-button>
              <el-button type="text" @click="open(scope.row)" v-else>
                <el-link type="primary">开启</el-link>
              </el-button>
              <el-button type="text" @click="move(scope.row, 'NO')" v-show="scope.$index">
                <el-link type="primary">上移</el-link>
              </el-button>
              <el-button type="text" @click="move(scope.row, 'YES')" v-show="scope.$index < tableData.length - 1">
                <el-link type="primary">下移</el-link>
              </el-button>
              <el-button type="text" @click="edit(scope.row)" v-if="scope.row.type === 'NEW'">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="del(scope.row)" v-if="scope.row.type === 'NEW'">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
    <el-dialog title="删除" :visible.sync="deleteShow" width="30%">
      <span>删除后该支付方式将永久删除。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteShow = false">取 消</el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="隐藏" :visible.sync="hideShow" width="30%">
      <span>隐藏后PMS支付方式处无法显示。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideShow = false">取 消</el-button>
        <el-button type="primary" @click="hideConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="addShow" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="支付方式" prop="name">
          <el-input v-model="form.name" placeholder="请输入内容" class="width-220"></el-input>
        </el-form-item>
        <el-form-item label="是否开启" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="ENABLED">开启</el-radio>
            <el-radio label="DISABLED">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="editShow" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="支付方式" prop="name">
          <el-input v-model="form.name" placeholder="请输入内容" class="width-220"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { getModePaymentList, addModePayment, editModePayment, deleteModePayment, sortModePayment, setModePaymentstatus } from '@/api/pms/collectionSetting/collectionSetting'
  export default {
    name: 'collectionSetting',
    data() {
      return {
        crumbs: new Map([['PMS'], ['财务设置'], ['支付方式设置']]),
        tableData: [],
        form: {
          id: '',
          name: '',
          status: 'ENABLED',
        },
        rules: {
          name: [{ required: true, message: '请输入内容', trigger: 'blur' }],
          status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        },
        addShow: false,    // 添加 弹窗
        hideShow: false,   // 隐藏 弹窗
        editShow: false,   // 编辑 弹窗
        deleteShow: false, // 删除 弹窗
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        if (oldVal && newVal.id !== oldVal.id) {
          this.getData()
        }
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      // 获取支付方式列表
      getData() {
        getModePaymentList({ hotelId: this.hotelInfo.id }).then(({ records, success }) => {
          if (success) {
            this.tableData = records
          }
        })
      },
      // 添加
      add() {
        this.form.name = ''
        this.form.status = 'ENABLED'
        this.addShow = true
      },
      // 编辑
      edit(item) {
        this.editShow = true
        this.form.id = item.id
        this.form.name = item.name
      },
      // 打开
      open(item) {
        let data = {
          id: item.id,
          status: item.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
        }
        setModePaymentstatus(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '设置成功!', type: 'success' })
            this.getData()
          }
        })
      },
      // 隐藏
      hide(item) {
        this.hideShow = true
        this.form.status = item.status
        this.form.id = item.id
      },
      // 移动
      move(item, sort) {
        let data = {
          id: item.id,
          isDesc: sort,
        }
        sortModePayment(data).then(({ success }) => {
          if (success) {
            this.getData()
          }
        })
      },
      // 删除
      del(item) {
        this.deleteShow = true
        this.form.id = item.id
      },
      // 隐藏 支付方式
      hideConfirm() {
        let data = {
          id: this.form.id,
          status: this.form.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
        }
        setModePaymentstatus(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '设置成功!', type: 'success' })
            this.hideShow = false
            this.getData()
          }
        })
      },
      // 添加 支付方式
      addConfirm() {
        let data = {
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId,
          name: this.form.name,
          status: this.form.status,
        }
        addModePayment(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '添加成功!', type: 'success' })
            this.addShow = false
            this.getData()
          }
        })
      },
      // 编辑 支付方式
      editConfirm() {
        let data = {
          id: this.form.id,
          name: this.form.name,
        }
        editModePayment(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '编辑成功!', type: 'success' })
            this.editShow = false
            this.getData()
          }
        })
      },
      // 删除 支付方式
      deleteConfirm() {
        let data = new FormData()
        data.append('id', this.form.id)
        deleteModePayment(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '删除成功!', type: 'success' })
            this.deleteShow = false
            this.getData()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
